import React, { Suspense, useEffect } from "react";
import { createRoot } from "react-dom/client";
import "./styles.css";
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { AuthContextProvider } from "./auth/AuthContext";
import ProtectedRoute from "./auth/components/ProtectedRoute";
import UnProtectedRoute from "./auth/components/UnProtectedRoute";
import { SnackbarProvider } from "notistack";
import { ApiContextProvider } from "api/ApiContext";
import { createTheme, ThemeProvider } from "@mui/material";
import { timbalGrey } from "components/CustomColors";
import { ApiAuthContextProvider } from "auth/ApiAuthContext";
import LoaderWithText from "components/LoaderWithText";
import { ErrorBoundary } from '@highlight-run/react';
import { H } from 'highlight.run';
import { DEBUG } from "utils/miscelanea";
import AuthApiRouter from "home/AuthApiRouter";
const LoginPage = React.lazy(() => import("./auth/login/LoginPage"));
const SignUpPage = React.lazy(() => import("auth/signup/SignUpPage"));
const RecoverPasswordPage = React.lazy(() => import("auth/recoverPassword/RecoverPasswordPage"));
const VerifyEmailPage = React.lazy(() => import("auth/verifyemail/VerifyEmailPage"));
const root = createRoot(document.getElementById("root"));

const theme = createTheme({
  typography: {
    fontFamily: "'DM Sans', sans-serif",
  },
  mixins: {
    MuiDataGrid: {
      containerBackground: timbalGrey[100],
    }
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          wordWrap: "break-word"
        },
      },
    },
  }
});

if (process.env.NODE_ENV === 'production') {
  H.init('jgo4n66d', {
    serviceName: "frontend-app",
    tracingOrigins: true,
    networkRecording: {
      enabled: true,
      recordHeadersAndBody: true,
      urlBlocklist: [
        // insert full or partial urls that you don't want to record here
        // Out of the box, Highlight will not record these URLs (they can be safely removed):
        "https://www.googleapis.com/identitytoolkit",
        "https://securetoken.googleapis.com",
      ],
    },
  });
}

const App = () => {
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const inviteToken = urlParams.get('invite_token');
    if (inviteToken) {
      localStorage.setItem('inviteToken', inviteToken);
      // Remove the token from the URL to keep it clean
      window.history.replaceState({}, document.title, window.location.pathname);
    }

    window.token = () => {
      if (DEBUG) {
        console.log(localStorage.getItem('x-auth-token'))
      }
    }
  }, []);

  return <ThemeProvider theme={theme}>
    <SnackbarProvider maxSnack={3} autoHideDuration={3737} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
      <AuthContextProvider>
        <ApiContextProvider>
          <BrowserRouter>
            <Suspense fallback={<LoaderWithText />}>
              <Routes>
                <Route path="/healthcheck" element={<div>OK</div>} />
                <Route path="/verifyemail" element={<UnProtectedRoute><VerifyEmailPage /></UnProtectedRoute>} />
                <Route path="/recoverpassword" element={<UnProtectedRoute><RecoverPasswordPage /></UnProtectedRoute>} />
                <Route path="/signup" element={<UnProtectedRoute><SignUpPage /></UnProtectedRoute>} />
                <Route path="/login" element={<UnProtectedRoute><LoginPage /></UnProtectedRoute>} />
                <Route path="/*"
                  element={
                    <ProtectedRoute>
                      <ApiAuthContextProvider>
                        <Routes>
                          <Route path="/*" element={<AuthApiRouter />} />
                        </Routes>
                      </ApiAuthContextProvider>
                    </ProtectedRoute>
                  }
                />
              </Routes>
            </Suspense>
          </BrowserRouter>
        </ApiContextProvider>
      </AuthContextProvider>
    </SnackbarProvider>
  </ThemeProvider>
}

if (process.env.NODE_ENV === 'production') {
  root.render(
    <ErrorBoundary>
      <App />
    </ErrorBoundary>
  );
} else {
  root.render(<App />);
}

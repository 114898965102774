const millisToDate = (miliseconds) => {
    const date = new Date(miliseconds);
    // Xs ago
    // Xm ago
    // Xh ago
    // Xd ago
    // Xw ago
    const now = new Date();
    const diff = now - date;
    const seconds = diff / 1000;
    if (seconds < 60) {
        return `${Math.floor(seconds)}s ago`;
    }
    const minutes = seconds / 60;
    if (minutes < 60) {
        return `${Math.floor(minutes)}m ago`;
    }
    const hours = minutes / 60;
    if (hours < 24) {
        return `${Math.floor(hours)}h ago`;
    }
    const days = hours / 24;
    if (days < 7) {
        return `${Math.floor(days)}d ago`;
    }
    const weeks = days / 7;
    if (weeks < 5) {
        return `${Math.floor(weeks)}w ago`;
    }
    const months = days / 30;
    if (months < 12) {
        return `${Math.floor(months)}mo ago`;
    }
    const years = days / 365;
    if (years < 100) {
        return `${Math.floor(years)}y ago`;
    }
}

function prettyInt(number) {
    if (number < 10000) {
        return `${number}`;
    }
    // from 1000 (1k) to 10000 (999k) with 1 decimal
    if (number < 1000000) {
        return `${(number / 1000).toFixed(1)}k`;
    }
    // from 1M to 999M with 1 decimal
    if (number < 1000000000) {
        return `${(number / 1000000).toFixed(1)}M`;
    }
    // from 1B to 999B with 1 decimal
    if (number < 1000000000000) {
        return `${(number / 1000000000).toFixed(1)}B`;
    }
}

function prettyBytes(bytes) {
    if (bytes < 1024) {
        return `${bytes} B`;
    }
    if (bytes < 1024 * 1024) {
        return `${(bytes / 1024).toFixed(1)} KB`;
    }
    if (bytes < 1024 * 1024 * 1024) {
        return `${(bytes / 1024 / 1024).toFixed(1)} MB`;
    }
    return `${(bytes / 1024 / 1024 / 1024).toFixed(1)} GB`;
}

// 02/05/2024 12:00:00
function prettyDate(miliseconds) {
    const date = new Date(miliseconds);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hour = String(date.getHours()).padStart(2, '0');
    const minute = String(date.getMinutes()).padStart(2, '0');
    const second = String(date.getSeconds()).padStart(2, '0');
    return `${day}/${month}/${year} ${hour}:${minute}:${second}`;
}

function prettyDateShort(miliseconds) {
    const date = new Date(miliseconds);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = String(date.getFullYear()).slice(-2);
    return `${day}/${month}/${year}`;
}

function prettyTime(milliseconds) {
    // Discard the last 70 milliseconds
    const roundedMilliseconds = Math.floor(milliseconds / 100) * 100;
    const seconds = roundedMilliseconds / 1000;
    const minutes = seconds / 60;

    if (roundedMilliseconds < 1000) {
        return `${roundedMilliseconds} ms`;
    }
    if (seconds < 60) {
        return `${seconds.toFixed(1)} s`;
    }
    const remainingMinutes = Math.floor(minutes);
    const remainingSeconds = Math.floor(seconds % 60);
    return `${remainingMinutes} m ${remainingSeconds} s`;
}

function prettyTimeSeconds(miliseconds) {
    const seconds = miliseconds / 1000;
    const minutes = seconds / 60;

    if (seconds < 60) {
        return `${seconds.toFixed(1)} s`;
    }

    const remainingMinutes = Math.floor(minutes);
    const remainingSeconds = Math.floor(seconds % 60);
    return `${remainingMinutes} m ${remainingSeconds} s`;
}



function cursorEnd(element) {
    let len = element.value.length;

    // Mostly for Web Browsers
    if (element.setSelectionRange) {
        element.focus();
        element.setSelectionRange(len, len);
    } else if (element.createTextRange) {
        let t = element.createTextRange();
        t.collapse(true);
        t.moveEnd('character', len);
        t.moveStart('character', len);
        t.select();
    }
}

function capitalizeFirst(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

function stringToColor(string) {
    let hash = 0;
    let i;
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }
    let color = '#';
    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
    }
    return color;
}

//const DEBUG = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';
const DEBUG = true;

const STRIPE_BILLING_URL = 'https://billing.stripe.com/p/login/9AQ8zDaSbgSNcOkbII';

// Export the function
export {
    millisToDate,
    DEBUG,
    stringToColor,
    cursorEnd,
    capitalizeFirst,
    prettyInt,
    prettyDate,
    prettyDateShort,
    prettyTime,
    prettyTimeSeconds,
    prettyBytes,
    STRIPE_BILLING_URL
};
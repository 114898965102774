import React, { useState, useEffect } from 'react';
import LoaderWithText from 'components/LoaderWithText';
import CommingSoonBlock from './components/CommingSoonBlock';
import ModalJoinedOrg from './pages/organitzationPage/ModalJoinedOrg';
import { useApiAuth } from 'auth/ApiAuthContext';
import { useApi } from 'api/ApiContext';
import useDocumentTitle from 'utils/useDocumentTitle';
import { lazy } from 'react';
import { Routes, Route } from 'react-router-dom';
import { Suspense } from 'react';

const MainPage = lazy(() => import('canva/MainPage'));
const InterfacesPage = lazy(() => import('interfaces/InterfacesPage'));
const DashboardPage = lazy(() => import('home/DashboardPage'));



const AuthApiRouter = () => {
    const [openModalJoinedOrg, setOpenModalJoinedOrg] = useState(true);
    const [orgDetails, setOrgDetails] = useState(null);

    useDocumentTitle('Dashboard');

    const { userApi } = useApiAuth();
    const { postUsersOrgsJoin } = useApi();

    useEffect(() => {
        if (userApi) {
            const checkInviteToken = async () => {
                try {
                    if (localStorage.getItem('inviteToken')) {
                        const inviteToken = localStorage.getItem('inviteToken');
                        const response = await postUsersOrgsJoin(inviteToken);
                        console.log('response', response);
                        if (response.success) {
                            localStorage.removeItem('inviteToken');
                            // Check if the user is already in the org 204 or 200
                            if (response.success !== 'OK') {
                                setOrgDetails(response.success);
                                setOpenModalJoinedOrg(true);
                            }
                        } else {
                            localStorage.removeItem('inviteToken');
                        }
                    }
                } catch (error) {
                    console.log('response error removing invite token', error);
                    localStorage.removeItem('inviteToken');
                }
            }
            checkInviteToken();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userApi]);

    if (!userApi) {
        return <LoaderWithText />
    }

    if (!userApi.isEarlyAccess) {
        return <>
            <CommingSoonBlock />
            {orgDetails && <ModalJoinedOrg openModalJoinedOrg={openModalJoinedOrg} setOpenModalJoinedOrg={setOpenModalJoinedOrg} orgDetails={orgDetails} />}
        </>

    }

    return <>
        <Suspense fallback={<LoaderWithText />}>
            <Routes>
                <Route path="/apps/:id" element={<MainPage />} />
                <Route path="/view/*" element={<InterfacesPage />} />
                <Route path="/*" element={<DashboardPage />} />
            </Routes>
        </Suspense>
        {orgDetails && <ModalJoinedOrg openModalJoinedOrg={openModalJoinedOrg} setOpenModalJoinedOrg={setOpenModalJoinedOrg} orgDetails={orgDetails} />}
    </>
}

export default AuthApiRouter;
import React from 'react';
import { Backdrop, Box, CircularProgress, Typography } from '@mui/material';

const LoaderWithText = ({ text }) => {
    return (<Backdrop
        sx={{
            backgroundColor: 'rgba(255, 255, 255, 0.6)',
            backdropFilter: 'blur(2px)', zIndex: (theme) => theme.zIndex.drawer + 1
        }}
        open={true}
    >
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2 }}>
            <CircularProgress size={30} color="inherit" />
            {text && <Typography variant="body2" sx={{ fontWeight: 700 }}> {text}</Typography>}
        </Box>
    </Backdrop >);

}

export default LoaderWithText;
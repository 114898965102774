import { useContext, createContext } from "react";
import { useAuth } from "auth/AuthContext";
import { DEBUG } from "utils/miscelanea";
import { useSnackbar } from "notistack";

const ApiContext = createContext();

export const ApiContextProvider = ({ children }) => {

    const { refreshToken } = useAuth();
    const baseApiUrl = 'https://monolith.timbal.ai'
    const { enqueueSnackbar } = useSnackbar();

    const timbalFetch = async (url, options = {}, retry = true) => {
        // Add Authorization header
        options.headers = {
            ...options.headers,
            'x-auth-token': localStorage.getItem('x-auth-token')
        };
        // Add dev flag if dev key in local storage
        if (localStorage.getItem('x-dev-key')) {
            options.headers['x-dev-key'] = localStorage.getItem('x-dev-key');
        }

        if (localStorage.getItem('selectedOrg')) {
            options.headers['x-org-id'] = JSON.parse(localStorage.getItem('selectedOrg')).id;
        }

        try {
            const response = await fetch(baseApiUrl + url, options);
            if (response.status === 401 && retry) {
                // Attempt to refresh the token
                await refreshToken();
                // Retry the original request
                return timbalFetch(url, options, retry = false);
            }
            if (response.status === 400) {
                const errorData = await response.text();
                return { error: errorData };
            }
            if (response.status === 403) {
                enqueueSnackbar('You are not allowed to access this resource', { variant: 'error' });
                return { error: 'Forbidden' };
            }
            if (response.status === 422) {
                const errorData = await response.text();
                enqueueSnackbar(errorData.message || 'Unexpected error occurred', { variant: 'warning' });
                return { error: errorData };
            }
            if (response.status === 500) {
                enqueueSnackbar('Internal server error', { variant: 'error' });
                return { error: 'Internal server error' };
            }
            if (!response.ok) {
                console.error('Refresh error:', response);
                return null;
            }
            if (response.status === 204) {
                if (DEBUG) {
                    console.log('Fetch: ' + url, { success: 'OK' });
                }
                return { success: 'OK' };
            }
            else {
                const data = await response.json();
                if (DEBUG) {
                    console.log('Fetch: ' + url, { success: data });
                }
                return { success: data };
            }
        } catch (error) {
            console.error('Fetch error:', error);
            return null;
        }
    };

    const getSession = async () => {
        const response = await timbalFetch('/session');
        if (response) {
            return response;
        }
    };

    const getIntegrations = async () => {
        const response = await timbalFetch('/integrations');
        if (response) {
            return response;
        }
    }

    // POST /integrations/revoke
    // @body {string} integrationId
    // -> 204
    const postRevokeIntegration = async (integrationId) => {
        const response = await timbalFetch('/integrations/revoke', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ integrationId })
        });
        if (response) {
            return response;
        }
    }

    //GET GET /integrations/auth_url?integrationId=xxx
    // -> 200
    const getAuthIntegrationUrl = async (integrationId) => {
        const response = await timbalFetch(`/integrations/auth_url?integrationId=${integrationId}`);
        if (response) {
            return response;
        }
    }

    // POST /apps
    // @param {string?} name
    // (podem afegir mes coses depenent de la ux que volgueu fer)

    // -> 200 { flowId: string }

    const postApps = async (name) => {
        const response = await timbalFetch('/apps', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ name })
        });
        if (response) {
            return response;
        }
    }

    // PATCH /apps/:appId
    // @param {string} appId
    // @param {string} name

    // -> 204

    const patchApps = async (appId, name, description) => {
        const response = await timbalFetch(`/apps/${appId}`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ name, description })
        });
        if (response) {
            return response;
        }
    }


    // GET /apps
    // @param {string?} page_token
    // @param {string?} search_query
    // @param {string?} sort_by
    // @param {string?} sort_order

    const getApps = async ({ page_token = null, search_query = null, sort_by = null, sort_order = null } = {}) => {
        let query = page_token ? `?page_token=${page_token}` : '';
        if (search_query) {
            query += query ? `&search_query=${search_query}` : `?search_query=${search_query}`;
        }
        if (sort_by) {
            query += query ? `&sort_by=${sort_by}` : `?sort_by=${sort_by}`;
        }
        if (sort_order) {
            query += query ? `&sort_order=${sort_order}` : `?sort_order=${sort_order}`;
        }
        const response = await timbalFetch(`/apps${query}`);
        if (response) {
            return response;
        }
    }

    //GET /flows
    // @param {string?} flowId

    // -> 200 { flow: Flow }

    const getApp = async (appId) => {
        const response = await timbalFetch(`/apps/${appId}`);
        if (response) {
            return response;
        }
    }

    // GET /apps/explore
    // @param {string?} search_query
    // @param {array<string>?} tags_ids
    // @param {string?} page_token
    // @param {string?} sort_by
    // @param {string?} sort_order

    // -> 200 {stepsVersions: array<StepVersion>, nextPageToken: string?}

    const getExploreApps = async ({ search_query = null, tags_ids = null, page_token = null, sort_by = null, sort_order = null } = {}) => {
        let query = page_token ? `?page_token=${page_token}` : '';
        if (search_query) {
            query += query ? `&search_query=${search_query}` : `?search_query=${search_query}`;
        }
        if (tags_ids) {
            query += query ? `&tags_ids=${tags_ids.join(',')}` : `?tags_ids=${tags_ids.join(',')}`;
        }
        if (sort_by) {
            query += query ? `&sort_by=${sort_by}` : `?sort_by=${sort_by}`;
        }
        if (sort_order) {
            query += query ? `&sort_order=${sort_order}` : `?sort_order=${sort_order}`;
        }
        const response = await timbalFetch(`/apps/explore${query}`);
        if (response) {
            return response;
        }
    }

    //     GET /steps/tags

    // -> 200 { tags: Vec<Tag> }

    const getAppsTags = async ({ search_query = null, page_token = null, type = null, sort_by = null, sort_order = null } = {}) => {
        let query = page_token ? `?page_token=${page_token}` : '';
        if (search_query) {
            query += query ? `&search_query=${search_query}` : `?search_query=${search_query}`;
        }
        if (type) {
            query += query ? `&type=${type}` : `?type=${type}`;
        }
        if (sort_by) {
            query += query ? `&sort_by=${sort_by}` : `?sort_by=${sort_by}`;
        }
        if (sort_order) {
            query += query ? `&sort_order=${sort_order}` : `?sort_order=${sort_order}`;
        }
        const response = await timbalFetch(`/apps/tags${query}`);
        if (response) {
            return response;
        }
    }

    //     POST /flows/delete
    // @param {string} flowId

    // -> 204

    const postAppsDelete = async (appId) => {
        const response = await timbalFetch(`/apps/${appId}/delete`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({})
        });
        if (response) {
            return response;
        }
    }

    // POST /users/api_tokens
    // @body {string} name

    // -> 200 { apiToken:  }

    const postUsersApiTokens = async (name) => {
        const response = await timbalFetch('/users/api_tokens', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ name })
        });
        if (response) {
            return response;
        }
    }


    // DELETE /users/api_tokens
    // @body {string} apiTokenId

    // -> 204

    const deleteUsersApiTokens = async (apiTokenId) => {
        const response = await timbalFetch('/users/api_tokens', {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ apiTokenId })
        });
        if (response) {
            return response;
        }
    }


    // GET /users/api_tokens

    // -> 200: {
    //     "api_tokens": [
    //         {
    //             "id": 2,
    //             "name": "Test",
    //             "hiddenToken": "t1_i94***********",
    //             "createdAt": 1727216534000
    //         }
    //     ]
    // }

    const getUsersApiTokens = async () => {
        const response = await timbalFetch('/users/api_tokens');
        if (response) {
            return response;
        }
    }

    // POST /apps/:appId/versions
    // @param {string} appId
    // @param {string} name

    // -> 204

    const postAppsVersions = async (appId, name) => {
        const response = await timbalFetch(`/apps/${appId}/versions`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ name })
        });
        if (response) {
            return response;
        }
    }

    // GET /runs
    // @param {string?} flowId
    // @param {string?} status
    // @param {string?} source
    // @param {epoch?} createdAfter
    // @param {epoch?} createdBefore
    // @param {string?} pageToken

    // -> 200 { runs: Vec<Run>, nextPageToken: string? }

    const getRuns = async ({ app_id = null, user_id = null, status = null, source = null, created_after = null, created_before = null, page_token = null } = {}) => {
        let query = page_token ? `?page_token=${page_token}` : '';
        if (app_id) {
            query += query ? `&app_id=${app_id}` : `?app_id=${app_id}`;
        }
        if (user_id) {
            query += query ? `&user_id=${user_id}` : `?user_id=${user_id}`;
        }
        if (status) {
            query += query ? `&status=${status}` : `?status=${status}`;
        }
        if (source) {
            query += query ? `&source=${source}` : `?source=${source}`;
        }
        if (created_after) {
            query += query ? `&created_after=${created_after}` : `?created_after=${created_after}`;
        }
        if (created_before) {
            query += query ? `&created_before=${created_before}` : `?created_before=${created_before}`;
        }
        const response = await timbalFetch(`/runs${query}`);
        if (response) {
            return response;
        }
    }

    // GET /runs/:run_id
    // @param {string} run_id

    // -> 200 { run: Run }

    const getRun = async (run_id) => {
        const response = await timbalFetch(`/runs/${run_id}`);
        if (response) {
            return response;
        }
    }

    // GET /apps/:appId/versions

    // -> 200 { nextPageToken: string?, flow_versions: FlowVersion }

    const getAppsVersions = async (appId, page_token = null) => {
        const query = page_token ? `?page_token=${page_token}` : '';
        const response = await timbalFetch(`/apps/${appId}/versions${query}`);
        if (response) {
            return response;
        }
    }

    // POST /uploads/presign_put
    // @body {int} contentLength
    // @body {string} contentType
    // @body {string} extension // e.g. “.jpg” amb el punt!

    // -> 200 { upload_method: str, upload_uri: str, upload_headers: map<str, str>, content_url: str }

    /**
     * Post a request to get a presigned URL to upload a file to S3
     * @param {int} contentLength The length of the file in bytes
     * @param {string} contentType The content type of the file
     * @param {string} extension The extension of the file
     * @returns {object} The response object
     * @returns {string} response.upload_method The method to use for the upload
     * @returns {string} response.upload_uri The URI to upload the file to
     * @returns {object} response.upload_headers The headers to use for the upload
     * @returns {string} response.content_url The URL to access the uploaded file
     * @returns {null} null If the request fails
     */

    const postUploadsPresignPut = async (contentLength, contentType, fileName) => {
        const response = await timbalFetch('/uploads/presign_put', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ contentLength, contentType, fileName })
        });
        if (response) {
            return response;
        }
    }

    // GET /users/orgs?status=active

    // -> 200 { orgs_associations: Vec<OrgAssociation>, next_page_token: string? }

    const getUsersOrgs = async () => {
        const response = await timbalFetch('/users/orgs?status=active');
        if (response) {
            return response;
        }
    }

    // GET /orgs
    // @param {string} org_id

    // -> 200 {
    //     "id": "1",
    //     "name": "Timbal AI",
    //     "icon_url": string?,
    //     "address_line_1": string?,
    //     "address_line_2": string?,
    //     "country": string?,
    //     "state": string?,
    //     "city": string?,
    //     "postal_code":string?,
    //     "vat_id": string?,
    // }

    const getOrgsDetails = async (org_id) => {
        const response = await timbalFetch(`/orgs/details?org_id=${org_id}`);
        if (response) {
            return response;
        }
    }

    // POST /orgs/details
    // @body -> 

    // #[derive(Debug, Deserialize)]
    // pub struct ReqBody {
    //     #[serde(deserialize_with = "deserialize_string_to_i64")]
    //     pub org_id: i64,
    //     #[serde(default, with = "::serde_with::rust::double_option")]
    //     pub name: Option<Option<String>>,
    //     #[serde(default, with = "::serde_with::rust::double_option")]
    //     pub icon_url: Option<Option<String>>,
    //     #[serde(default, with = "::serde_with::rust::double_option")]
    //     pub address_line_1: Option<Option<String>>,
    //     #[serde(default, with = "::serde_with::rust::double_option")]
    //     pub address_line_2: Option<Option<String>>,
    //     #[serde(default, with = "::serde_with::rust::double_option")]
    //     pub country: Option<Option<String>>,
    //     #[serde(default, with = "::serde_with::rust::double_option")]
    //     pub city: Option<Option<String>>,
    //     #[serde(default, with = "::serde_with::rust::double_option")]
    //     pub postal_code: Option<Option<String>>,
    //     #[serde(default, with = "::serde_with::rust::double_option")]
    //     pub vat_id: Option<Option<String>>,
    // }

    // -> 204

    const postOrgsDetails = async (org_id, { name = null, icon_url = null, address_line_1 = null, address_line_2 = null, country = null, state = null, city = null, postal_code = null, vat_id = null } = {}) => {
        const response = await timbalFetch('/orgs/details', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ org_id, name, icon_url, address_line_1, address_line_2, country, state, city, postal_code, vat_id })
        });
        if (response) {
            return response;
        }
    }

    // GET /orgs/users
    // @param {string} org_id
    // @param {string?} search_query
    // @param {string?} status (“active”, “pending”)
    // @param {string?} role (“owner”, “admin”, “member”)
    // @param {string?} sort_by (“name”, “email”, “role”, “status”, created_at”)
    // @param {string?} sort_order (“asc”, “desc”)

    // -> 200 {
    //     "org_users": [
    //         {
    //             "id": "10",
    //             "username": null,
    //             "email": "dberges@timbal.ai",
    //             "role": "member",
    //             "status": "pending",
    //             "created_at": 1729438898000
    //         },
    //         {
    //             "id": "1",
    //             "username": "Isaac",
    //             "email": "grauisaac57@gmail.com",
    //             "role": "member",
    //             "status": "active",
    //             "created_at": 1729425904000
    //         },
    //         {
    //             "id": "4",
    //             "username": null,
    //             "email": "daviid.bergees@gmail.com",
    //             "role": "owner",
    //             "status": "active",
    //             "created_at": 1729098085000
    //         }
    //     ],
    //     "next_page_token": null
    // }

    const getOrgsUsers = async (org_id, { search_query = null, status = null, role = null, sort_by = null, sort_order = null } = {}) => {
        let query = `?org_id=${org_id}`;
        if (search_query) {
            query += `&search_query=${search_query}`;
        }
        if (status) {
            query += `&status=${status}`;
        }
        if (role) {
            query += `&role=${role}`;
        }
        if (sort_by) {
            query += `&sort_by=${sort_by}`;
        }
        if (sort_order) {
            query += `&sort_order=${sort_order}`;
        }
        const response = await timbalFetch(`/orgs/users${query}`);
        if (response) {
            return response;
        }
    }

    // GET /orgs/users/:user_id

    const getOrgUser = async (org_id, { user_id } = {}) => {
        const response = await timbalFetch(`/orgs/users/${user_id}?org_id=${org_id}`);
        if (response) {
            return response;
        }
    }

    // POST /orgs/users/invite
    // @body -> {
    //     "org_id": "1",
    //     "invite_target": {
    //         "email": "dberges@momentsapp.es"
    //     }
    // }

    // -> 204


    const postOrgsUsersInvite = async (org_id, invite_target) => {
        const response = await timbalFetch('/orgs/users/invite', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ org_id, invite_target })
        });
        if (response) {
            return response;
        }
    }

    // POST /orgs/users/role
    // @body -> {
    //     "org_id": "1",
    //     "user_id": "1",
    //     "role": "member"
    // }

    // -> 204

    const postOrgsUsersRole = async (org_id, user_id, role) => {
        const response = await timbalFetch('/orgs/users/role', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ org_id, user_id, role })
        });
        if (response) {
            return response;
        }
    }

    // POST /orgs/users/remove
    // @body -> {
    //     org_id: string,
    //     user_id: string?,
    //     email: string?
    // }

    // -> 204

    const postOrgsUsersRemove = async (org_id, user_id, email) => {
        const response = await timbalFetch('/orgs/users/remove', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ org_id, user_id, email })
        });
        if (response) {
            return response;
        }
    }

    // POST /users/orgs/join
    // @body { invite_token: string }

    // -> 200 { org_details: OrgDetails }

    const postUsersOrgsJoin = async (invite_token) => {
        const response = await timbalFetch('/users/orgs/join', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ invite_token })
        });
        if (response) {
            return response;
        }
    }

    //     POST /sources
    // {
    //     “is_directory": bool,
    //     "name": string,
    //     "content_type": string?,
    //     "content_length": int?
    // }

    // -> 200 {source_id: string, uploader: Uploader}

    const postSources = async ({ is_directory, name, content_type = null, content_length = null, parent_id = null } = {}) => {
        const response = await timbalFetch('/sources', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ is_directory, name, content_type, content_length, parent_id })
        });
        if (response) {
            return response;
        }
    }

    // PATCH /sources/:source_id
    // {name: string?, url: string?}

    // -> 204

    const patchSources = async ({ source_id, name, url } = {}) => {
        const response = await timbalFetch(`/sources/${source_id}`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ name, url })
        });
        if (response) {
            return response;
        }
    }

    //     GET /sources
    // {parent_id: string?, search_query: string?, sort_by: enum[name, created_at]?, sort_order: enum[asc, desc]?, page_token: string?}

    // -> {sources: [Source], next_page_token: string?}
    // where Source: 
    // {id: string, parent_id: string?, role: ResourceRole?, name: string, is_directory: bool, metadata: map<string, any>, content_type: string?, content_length: int?, url: string?, created_at: epoch, updated_at: epoch}

    const getSources = async ({ parent_id = null, search_query = null, sort_by = null, sort_order = null, page_token = null } = {}) => {
        let query = page_token ? `?page_token=${page_token}` : '';
        if (parent_id) {
            query += query ? `&parent_id=${parent_id}` : `?parent_id=${parent_id}`;
        }
        if (search_query) {
            query += query ? `&search_query=${search_query}` : `?search_query=${search_query}`;
        }
        if (sort_by) {
            query += query ? `&sort_by=${sort_by}` : `?sort_by=${sort_by}`;
        }
        if (sort_order) {
            query += query ? `&sort_order=${sort_order}` : `?sort_order=${sort_order}`;
        }
        const response = await timbalFetch(`/sources${query}`);
        if (response) {
            return response;
        }
    }

    // DELETE /sources/:source_id
    // -> 204

    const deleteSources = async (source_id) => {
        const response = await timbalFetch(`/sources/${source_id}/delete`, { method: 'POST' });
        if (response) {
            return response;
        }
    }

    // POST /runs
    // -> 200 { run_id: string }

    const postRuns = async ({ version_id, inputs }) => {
        const response = await timbalFetch('/runs', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ version_id, inputs })
        });
        if (response) {
            return response;
        }
    }

    // PATCH /users
    // @body {string?} username
    // @body {string?} icon_url

    // -> 204

    const patchUsers = async ({ username, icon_url }) => {
        const response = await timbalFetch('/users', {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ username, icon_url })
        });
        if (response) {
            return response;
        }
    }

    // GET /apps/:app_id/users/available
    // -> 200 { users: [User] }

    const getAppsUsersAvailable = async (app_id) => {
        const response = await timbalFetch(`/apps/${app_id}/users/available`);
        if (response) {
            return response;
        }
    }

    // GET /sources/:source_id/users/available
    // -> 200 { users: [User] }

    const getSourcesUsersAvailable = async (source_id) => {
        const response = await timbalFetch(`/sources/${source_id}/users/available`);
        if (response) {
            return response;
        }
    }

    // GET /apps/:app_id/users
    // -> 200 { users: [User] }

    const getAppsUsers = async (app_id) => {
        const response = await timbalFetch(`/apps/${app_id}/users`);
        if (response) {
            return response;
        }
    }

    // PUT /apps/:app_id/users/:user_id
    // -> 204

    const putAppsUsers = async ({ app_id, user_id, role }) => {
        const response = await timbalFetch(`/apps/${app_id}/users/${user_id}`,
            {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ role })
            });
        if (response) {
            return response;
        }
    }

    // DELETE /apps/:app_id/users/:user_id
    // -> 204

    const deleteAppsUsers = async (app_id, user_id) => {
        const response = await timbalFetch(`/apps/${app_id}/users/${user_id}`, { method: 'DELETE' });
        if (response) {
            return response;
        }
    }

    // GET /sources/:source_id/users
    // -> 200 { users: [User] }

    const getSourcesUsers = async (source_id) => {
        const response = await timbalFetch(`/sources/${source_id}/users`);
        if (response) {
            return response;
        }
    }

    // PUT /sources/:source_id/users/:user_id
    // -> 204

    const putSourcesUsers = async ({ source_id, user_id, role }) => {
        const response = await timbalFetch(`/sources/${source_id}/users/${user_id}`,
            {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ role })
            });
        if (response) {
            return response;
        }
    }

    // DELETE /sources/:source_id/users/:user_id
    // -> 204

    const deleteSourcesUsers = async (source_id, user_id) => {
        const response = await timbalFetch(`/sources/${source_id}/users/${user_id}`, { method: 'DELETE' });
        if (response) {
            return response;
        }
    }

    // PATCH /apps/:app_id/versions/:version_id
    // @body {bool?} enable_form
    // @body {bool?} enable_chat
    // -> 204

    const patchAppsVersions = async ({ app_id, version_id, enable_form, enable_chat }) => {
        const response = await timbalFetch(`/apps/${app_id}/versions/${version_id}`,
            {
                method: 'PATCH',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ enable_form, enable_chat })
            });
        if (response) {
            return response;
        }
    }


    return (
        <ApiContext.Provider
            value={{
                getSession,
                getIntegrations,
                postRevokeIntegration,
                getAuthIntegrationUrl,
                postApps,
                patchApps,
                getApps,
                getApp,
                postAppsDelete,
                postUploadsPresignPut,
                getExploreApps,
                getAppsTags,
                postUsersApiTokens,
                deleteUsersApiTokens,
                getUsersApiTokens,
                postAppsVersions,
                getAppsVersions,
                getRuns,
                getRun,
                getUsersOrgs,
                getOrgsDetails,
                postOrgsDetails,
                getOrgsUsers,
                getOrgUser,
                postOrgsUsersInvite,
                postOrgsUsersRole,
                postOrgsUsersRemove,
                postUsersOrgsJoin,
                postSources,
                patchSources,
                getSources,
                deleteSources,
                postRuns,
                patchUsers,
                getAppsUsers,
                putAppsUsers,
                deleteAppsUsers,
                getSourcesUsers,
                putSourcesUsers,
                deleteSourcesUsers,
                getAppsUsersAvailable,
                getSourcesUsersAvailable,
                patchAppsVersions
            }}>
            {children}
        </ApiContext.Provider>
    );

};

export const useApi = () => {
    return useContext(ApiContext);
}
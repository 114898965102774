import { Dialog, Box, Typography, IconButton, Avatar } from "@mui/material";
import { CancelOutlined } from "@mui/icons-material";
import { stringToColor } from "utils/miscelanea";
import { MainButton } from "components/CustomButtons";

const ModalJoinedOrg = ({ openModalJoinedOrg, setOpenModalJoinedOrg, orgDetails }) => {

    const handleClose = () => {
        localStorage.setItem('selectedOrg', JSON.stringify(orgDetails));
        window.location.reload();
        setOpenModalJoinedOrg(false);
    }

    return (
        <Dialog
            open={openModalJoinedOrg}
            onClose={handleClose}
            onClick={(e) => e.stopPropagation()}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            sx={{
                borderRadius: '6px',
                '& .MuiBackdrop-root': {
                    backgroundColor: 'rgba(255, 255, 255, 0.5)',
                    backdropFilter: 'blur(2px)',
                }
            }}
            fullWidth
        >
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Box sx={{ p: 1, pb: 0 }}>
                    <IconButton size="small" sx={{ m: 0, color: 'black' }} onClick={handleClose}>
                        <CancelOutlined />
                    </IconButton>
                </Box>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4, p: 4, pt: 0, }}>
                <Typography variant="body2" sx={{ textAlign: 'center' }}>You have joined the organization.</Typography>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 1 }}>
                    <Avatar
                        variant="square"
                        src={orgDetails.icon_url}
                        sx={{
                            bgcolor: orgDetails.icon_url ? 'transparent' : stringToColor(orgDetails.name),
                            width: 100, height: 100, alignSelf: 'center', borderRadius: '6px'
                        }}
                        children={
                            <Typography variant="h3" sx={{ fontWeight: 500 }}>
                                {orgDetails.name.charAt(0)}
                            </Typography>
                        }
                    />
                    <Typography variant="h5" sx={{ textAlign: 'center', fontWeight: 700 }}>{orgDetails.name}</Typography>
                </Box>
                <Typography variant="body2" sx={{ textAlign: 'center' }}>You can now access all the features of the organization.</Typography>
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <MainButton onClick={handleClose}>
                        Go to Dashboard
                    </MainButton>
                </Box>
            </Box>
        </Dialog>
    )
}

export default ModalJoinedOrg;
// firebaseConfig.js
import { initializeApp } from 'firebase/app';
import { browserLocalPersistence, getAuth, GoogleAuthProvider } from 'firebase/auth';
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyCmGxIrDFP9AVOmuVV9bXqC0AypBn4dDKc",
  authDomain: "timbal-ai.firebaseapp.com",
  projectId: "timbal-ai",
  storageBucket: "timbal-ai.appspot.com",
  messagingSenderId: "217041975430",
  appId: "1:217041975430:web:5ba0779a24a5f2d9d53907",
  measurementId: "G-V71PFQED6T"
};

const app = initializeApp(firebaseConfig);

const auth = getAuth(app);
auth.setPersistence(browserLocalPersistence);

const googleProvider = new GoogleAuthProvider();
const analytics = getAnalytics(app);

export { auth, googleProvider, analytics };

import { useAuth } from 'auth/AuthContext';
import { Typography } from '@mui/material';
import { MainButton } from 'components/CustomButtons';
import { Link } from '@mui/material';
import { Box } from '@mui/material';
import Lottie from 'react-lottie';
import * as rocket from 'lotties/rocket.json';

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: rocket,
}

const CommingSoonBlock = ({ hideButton, sx }) => {
    const { logOut, currentUser } = useAuth();

    const handleClick = () => {
        logOut({
            redirectTo: 'https://timbal.ai'
        });
    }

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', backgroundColor: '#fefdf7', height: '100vh', justifyContent: 'center', alignItems: 'center', textAlign: 'center', ...sx }}>
            <img src='./timbal.svg' alt="logo" style={{ width: 150, marginRight: 20, height: 100 }} />
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                width: 430,
                px: 4,
                py: 2,
                borderRadius: '6px',
                gap: 4,
                mb: 10
            }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', gap: 2 }}>
                    <Lottie options={defaultOptions} height={200} width={200} />
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <Typography variant="body">
                            We’re putting the final touches on something special.
                        </Typography>
                        <Typography variant="body">
                            Your inbox will be the first to know when we flip the switch.
                        </Typography>
                        <Typography variant="body" sx={{ mt: 2 }}>
                            Stay tuned.
                        </Typography>
                    </Box>

                </Box>
                {!hideButton && <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', gap: 1 }}>
                    <Typography variant="body2">
                        {`Logged in as ${currentUser.email}`}
                    </Typography>
                    <MainButton onClick={handleClick}>
                        Go back
                    </MainButton>
                    <Link href="mailto:support@timbal.ai?subject=Early%20access%20request" underline='hover' color={'#5012cb'} fontWeight={700} sx={{ mt: 2 }}>
                        <Typography variant="body2">
                            Request early access
                        </Typography>
                    </Link>
                </Box>}

            </Box>
        </Box>
    )
}

export default CommingSoonBlock;

import { useContext, createContext, useEffect, useState } from 'react';
import { useApi } from 'api/ApiContext';
import { useAuth } from 'auth/AuthContext';
import { H } from 'highlight.run';

const ApiAuthContext = createContext();

export const ApiAuthContextProvider = ({ children }) => {

    const [userApi, setUserApi] = useState(null);
    const { getSession } = useApi();
    const { currentUser } = useAuth();

    useEffect(() => {
        const fetchUserApi = async () => {
            const resp = await getSession();
            if (resp.success) {
                localStorage.setItem('x-user', JSON.stringify(resp.success));
                setUserApi(resp.success);
                if (process.env.NODE_ENV === 'production') {
                    H.identify(resp.success.id, {
                        firebaseId: currentUser.uid,
                        email: currentUser.email,
                        name: currentUser.name,
                        session: resp.success
                    });
                }
            }
        }
        fetchUserApi();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <ApiAuthContext.Provider
            value={{ userApi }}>
            {children}
        </ApiAuthContext.Provider>
    );
};

export const useApiAuth = () => {
    return useContext(ApiAuthContext);
};
